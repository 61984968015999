import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable, inject } from '@angular/core';
import {
    fromEvent, merge, Observable, switchMap
} from 'rxjs';
import { ThemeDomService } from './theme-dom.service';
import { map } from 'rxjs/operators';
import { publishRef } from '@webclient/rx-share-utils';
import { SettingsService } from '@webclient/settings.service';

export interface IThemeOptions {
    label: string;
    value: ThemeType;
}

export type ThemeType = 'dark' | 'light' | 'system';
export type ColorScheme = Exclude<ThemeType, 'system'>

export const themeList: IThemeOptions[] = [
    { label: '_i18n.Light', value: 'light' },
    { label: '_i18n.DarkThemeColor', value: 'dark' },
    { label: '_i18n.SystemThemeColor', value: 'system' },
];

@Injectable({
    providedIn: 'root',
})
export class ThemeListenerService {
    private mdr = inject(MediaMatcher);
    private themeDomService = inject(ThemeDomService);
    settingsService = inject(SettingsService);

    dark: MediaQueryList;
    public readonly applicationTheme$: Observable<ThemeType>;
    public readonly applicationColorScheme$: Observable<ColorScheme>;
    public defaultTheme: ColorScheme = 'light';

    constructor() {
        const settingsService = this.settingsService;

        const localStorageThemeChanged$: Observable<ThemeType> = settingsService.generalSettings$.pipe(
            map(settings => settings.applicationTheme)
        );
        this.dark = this.mdr.matchMedia('(prefers-color-scheme: dark)');
        const systemThemeChanged$ = fromEvent(this.dark, 'change');

        this.applicationTheme$ = merge(localStorageThemeChanged$, systemThemeChanged$).pipe(
            switchMap(() => localStorageThemeChanged$),
            map((themeValue: ThemeType) => {
                // if the theme value is unknown revert to default theme
                return themeList.some((theme: IThemeOptions) => theme.value === themeValue) ? themeValue : this.defaultTheme;
            }),
            publishRef()
        );

        this.applicationColorScheme$ = this.applicationTheme$.pipe(map(
            selectedTheme => (selectedTheme === 'system' ? this.systemTheme : selectedTheme)
        ));
    }

    private get systemTheme(): ColorScheme {
        return this.dark.matches ? 'dark' : 'light';
    }

    public applyTheme(themeValue:string) {
        const selectedTheme = themeList.some((theme: IThemeOptions) => theme.value === themeValue) ? themeValue : this.defaultTheme;
        this.themeDomService.applyApplicationTheme(selectedTheme === 'system' ? this.systemTheme : selectedTheme);
    }

    selectTheme(themeValue: string) {
        this.settingsService.setField('applicationTheme', themeValue);
    }
}
