import { Injectable, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ThemeDomService {
    private _doc: Document;

    constructor() {
        const document = inject<Document>(DOCUMENT);

        this._doc = document;
    }

    applyApplicationTheme(theme: string) {
        this._doc.body.setAttribute('data-theme', theme);
    }
}
